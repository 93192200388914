<template>
  <b-card style="" class="overflow-auto">
    <h4>{{ $t("plantUsers") }}</h4>
    <b-row>
      <!-- Plant Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
      </b-col>
      <!-- Right Col: Table -->
      <b-col
        v-for="(user, index) in plantUsersData"
        :key="user.id"
        cols="12"
        xl="12"
        class="d-flex justify-content-center align-items-center mt-1"
      >
        <b-avatar
          variant="primary"
          :src="'https://api.optitmr.com/User_Pictures/' + user.pictureurl"
          text="BV"
          class="mr-1"
        ></b-avatar>
        <span class="mr-auto"> {{ user.fullname }} </span>
        <b-form-checkbox
          :checked="user.selected == 1"
          @change="changed(index)"
        ></b-form-checkbox>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BFormCheckbox, BAvatar } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BAvatar,
  },
  props: {
    plantUsers: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return { plantUsersData: [] };
  },
  mounted() {
    this.plantUsersData.splice(0, 0, ...this.plantUsers);
  },
  methods: {
    changed(index) {
      this.plantUsersData[index].selected =
        this.plantUsersData[index].selected == 1 ? 0 : 1;
      var ids = [];
      this.plantUsersData.map((x) => {
        if (x.selected == 1) ids.push(x.id);
      });
      this.$emit("userupdate", ids.join(","));
    },
  },
};
</script>
