<template>
  <b-card>
    <b-card-title class="d-flex justify-content-between align-items-center">
      <h4>{{ $t("plantOrders") }}</h4>

      <h6>
        <b-link class="alert-link" :to="{ name: 'plantOrders' }">
          {{ $t("seeAll") }} {{ orders.length }} / {{ allOrdersCount }}
        </b-link>
      </h6></b-card-title
    >
    <div v-if="orders.length > 0">
      <order-list
        :orders="orders"
        :table-columns="tableColumns"
        :to-route-name="'plantOrderPreview'"
        :sort-by="'s'"
        :sort-desc="true"
        :plantid="$route.params.id.toString()"
      ></order-list>
    </div>
    <div v-else>
      <span>{{ $t("xNotFound1", { value: $t("orders") }) }}</span>
    </div>
    <div class="d-flex justify-content-end">
      <b-button
        class="text-sm align-self-end"
        variant="success"
        size="sm"
        :to="{ name: 'addOrder' }"
      >
        {{ $t("newOrder") }}
      </b-button>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BMedia,
  BAvatar,
  BCardTitle,
  BLink,
} from "bootstrap-vue";
import { getUserData } from "@/auth/utils";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import tr from "dayjs/locale/tr";
import az from "dayjs/locale/az";
import ru from "dayjs/locale/ru";
import OrderList from "../../components/OrderList.vue";
export default {
  components: {
    BCard,
    OrderList,
    BLink,
    BCardTitle,
    BButton,
  },
  data: function () {
    return {
      orders: [],
      allOrdersCount: 0,
      tableColumns: [
        { key: "plantname", label: this.$t("plantName") },
        {
          key: "reportdate",
          formatter: (val) =>
            `${dayjs(val)
              .locale(`${this.$i18n.locale}`)
              .format("DD MMMM YYYY")}`,
        },
        {
          key: "duedate",
          label: this.$t("dueDate"),
          formatter: (val) =>
            `${dayjs(val)
              .locale(`${this.$i18n.locale}`)
              .format("DD MMMM YYYY")}`,
        },
        {
          key: "orderdate",
          label: this.$t("orderDate"),
          formatter: (val) =>
            `${dayjs(val)
              .locale(`${this.$i18n.locale}`)
              .format("DD MMMM YYYY")}`,
        },
      ],
    };
  },
  async mounted() {
    this.fetchOrders();
  },
  methods: {
    async fetchOrders() {
      var orders = await this.$store.dispatch(
        "plantsModule/fetchPlantOrders",
        this.$store.state.app.selectedPlantId
      );
      this.allOrdersCount = orders.length;
      this.orders = orders.slice(0, 5);
    },
  },
};
</script>
