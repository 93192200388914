<template>
  <b-card>
    <b-card-title class="d-flex justify-content-between">
      <h4>{{ $t("plantVisits") }}</h4>
      <h6>
        <b-link class="alert-link" :to="{ name: 'plantVisits' }">
          {{ $t("seeAll") }} {{ visits.length }} / {{ allVisitsCount }}
        </b-link>
      </h6></b-card-title
    >
    <div v-if="visits.length > 0">
      <visit-list
        :visits="visits"
        :is-view-amount="false"
        @fetchvisit="fetchVisits"
      ></visit-list>
    </div>

    <div v-else>
      <span> {{ $t("xNotFound1", { value: $t("visit") }) }} </span>
    </div>
    <div class="d-flex justify-content-end">
      <b-button
        class="text-sm align-self-end"
        variant="success"
        size="sm"
        :to="{ name: 'plantVisits' }"
      >
        {{ $t("newVisit") }}
      </b-button>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BMedia,
  BAvatar,
  BCardTitle,
  BLink,
} from "bootstrap-vue";
import { getUserData } from "@/auth/utils";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import VisitList from "../../components/VisitList.vue";
export default {
  components: {
    BCard,
    VisitList,
    BLink,
    BCardTitle,
    BButton,
  },
  data: function () {
    return { visits: [], allVisitsCount: 0 };
  },
  async mounted() {
    this.fetchVisits();
  },
  methods: {
    async fetchVisits() {
      var visits = await this.$store.dispatch(
        "plantsModule/fetchPlantVisits",
        this.$store.state.app.selectedPlantId
      );
      this.allVisitsCount = visits.length;
      this.visits = visits.slice(0, 3);
    },
  },
};
</script>
