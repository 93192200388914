import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";
import VueI18n from "@/libs/i18n";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function usePlantList() {
  // Use toast
  const toast = useToast();

  const refPlantListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "select", label: VueI18n.t("select"), sortable: false },
    { key: "pictureurl", label: VueI18n.t("plantName") },
    { key: "address", label: VueI18n.t("address"), sortable: false },

    {
      key: "responsiblemobile",
      label: VueI18n.t("resonsibleMobile"),
      sortable: false,
    },
    {
      key: "modelid",
      label: VueI18n.t("model"),
      sortable: false,
    },
    { key: "email", label: VueI18n.t("email"), sortable: false },

    { key: "delete", label: VueI18n.t("delete") },
    { key: "edit", label: VueI18n.t("edit"), sortable: false },
  ];
  const perPage = ref(5);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [5, 10, 25, 50];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);
  const allPlants = ref([]);
  const filteredPlants = ref([]);

  const dataMeta = computed(() => {
    const localItemsCount = filteredPlants.value.length;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to:
        perPage.value * (currentPage.value - 1) +
          perPage.value * currentPage.value <
        localItemsCount
          ? perPage.value
          : localItemsCount,
      of: allPlants.value.length,
    };
  });
  const refetchData = () => {
    fetchPlants();

    if (searchQuery.value)
      filteredPlants.value = allPlants.value.filter((val) =>
        val.plantname.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
  };

  watch(
    [currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter],
    () => {
      refetchData();
    }
  );
  const fetchPlants = async (ctx, callback) => {
    var plant = await store
      .dispatch("plantsModule/fetchPlants")

      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("xFetchError", { value: VueI18n.t("plants") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    allPlants.value = plant;
    filteredPlants.value = [...plant];
    var selectedPlant = store.state.app.selectedPlantId;
    var selectedModel = store.state.app.selectedModelId;
    var currentModel = allPlants.value.find((x) => x.id == selectedPlant);
    if (currentModel) {
      store.commit("app/UPDATE_MODEL", currentModel.modelid);
    } else {
      store.commit("app/UPDATE_MODEL", selectedModel);
    }
    return plant;
  };
  const deletePlant = (ctx) => {
    store
      .dispatch("plantsModule/deletePlant", ctx)
      .then((response) => {
        fetchPlants();
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: VueI18n.t("deleted", { type: VueI18n.t("plant") }),
          },
        });
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("unsuccessful"),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: VueI18n.t("notDeleted", { type: VueI18n.t("plant") }),
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchPlants,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPlantListTable,
    deletePlant,
    filteredPlants,
    allPlants,

    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  };
}
